<template>
  <v-row>
    <v-col>
    <requirement label="Requerimiento de Servicios" :TypeRequirement=3></requirement></v-col>
  </v-row>
</template>
          

<script>
import Requirement from './Requirement.vue';
export default {
  name: "RequirementService",
  components: { Requirement},
  created(){
  },
  props: {
   
  },
  data: () => ({
   
  }),

};
</script>
